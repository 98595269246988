import { backendAPI, googleAPI } from 'api';
import { call, put, select } from 'redux-saga/effects';
import { FORM } from 'containers/AdsPilot/constants';
import { getProjectStatusFailed, getProjectStatusSuccess } from '../actions';
import {
  getConfiguredIteration,
  getFormType,
  getSelectedFBAdsAccount,
  getSelectedProjectId,
} from '../reducer';

const wrap = id => `/api/projects/${id}`;

export default function* handleStatus() {
  try {
    const form = yield select(getFormType);
    const project = yield select(getSelectedProjectId);
    const facebookAdsAccount = yield select(getSelectedFBAdsAccount);
    const iteration = yield select(getConfiguredIteration);

    switch (form) {
      case FORM.FB: {
        const data = yield call(backendAPI.facebookStatus, {
          project: wrap(project),
          facebookAdsAccount,
          iteration,
        });

        yield put(getProjectStatusSuccess(project, data));
        break;
      }
      case FORM.GOOGLE: {
        const data = yield call(googleAPI.status, {
          project: wrap(project),
        });

        yield put(getProjectStatusSuccess(project, data));
        break;
      }

      default:
        break;
    }
  } catch (e) {
    yield put(getProjectStatusFailed(e));
  }
}

/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { compose } from 'redux';

import { injectSaga } from 'redux-injectors';

import { DAEMON } from 'utils/constants';

import AdsPilot from 'containers/AdsPilot/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import LoginButton from 'containers/Auth/LoginButton';
import LogoutButton from 'containers/Auth/LogoutButton';
import GlobalStyle from 'global-styles';
import Settings from 'containers/Settings';
import Jobs from 'containers/Jobs';
import Modals from 'containers/Modals/Loadable';
import Projects from 'containers/App/projects/Loadable';
import saga from './saga';
import AppWrapper from './AppWrapper';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/settings/:id?">
          <AppWrapper>
            <Settings />
          </AppWrapper>
        </Route>
        <Route exact path="/jobs/:id?">
          <AppWrapper>
            <Jobs />
          </AppWrapper>
        </Route>
        <Route exact path="/projects/:id?">
          <AppWrapper>
            <Projects />
          </AppWrapper>
        </Route>
        <Route exact path="/">
          <AppWrapper>
            <Projects />
          </AppWrapper>
        </Route>
        <Route exact path="/login">
          <LoginButton />
        </Route>
        <Route exact path="/logout">
          <LogoutButton />
        </Route>
        <Route path="/:form?/:id?">
          <AppWrapper>
            <AdsPilot />
          </AppWrapper>
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
      <GlobalStyle />
      <Modals />
    </BrowserRouter>
  );
}

const withSaga = injectSaga({ key: 'ADS-PILOT-APP', saga, mode: DAEMON });

export default compose(withSaga)(App);

import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY || 'no-key-defined',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.APP_VERSION,
  releaseStage: process.env.APP_ENV,
  notifyReleaseStages: ['production', 'staging'],
});

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React,
);

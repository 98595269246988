import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { updateProjectSuccess, updateProjectFailed } from '../actions';

export default function* handleUpdateProject({ payload }) {
  try {
    const data = yield call(projectsAPI.updateProject, payload.id);

    yield put(updateProjectSuccess(data));
  } catch (e) {
    yield put(updateProjectFailed(e));
  }
}

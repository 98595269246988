import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  getProjectFacebookPagesSuccess,
  getProjectFacebookPagesFailed,
} from '../actions';

export default function* handleFetchProjectFacebookPages({ payload }) {
  try {
    const id = payload.id || payload.projectId;

    const data = yield call(projectsAPI.projectFacebookPages, id);

    yield put(getProjectFacebookPagesSuccess(id, data));
  } catch (e) {
    yield put(getProjectFacebookPagesFailed(e));
  }
}

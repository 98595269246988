import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/history';
import { Auth0Provider } from '@auth0/auth0-react';
import scopes from './scopes';

export const onRedirectCallback = (appState = {}) => {
  history.push(appState.returnTo || window.location.pathname);
};

const Auth0ProviderWithHistory = ({ children }) => (
  <Auth0Provider
    domain={process.env.AUTH_DOMAIN}
    clientId={process.env.AUTH_CLIENT_ID}
    audience={process.env.AUTH_AUDIENCE}
    redirectUri={window.location.origin}
    scope={scopes.join(' ')}
    onRedirectCallback={onRedirectCallback}
  >
    {children}
  </Auth0Provider>
);

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Auth0ProviderWithHistory;

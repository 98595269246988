import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getDashboardUrl } from 'utils/environment';

const UI = {
  DASHBOARD: 'Backercamp Dashboard',
  KICKSTARTER: 'Kickstarter Page',
};

const getDashboardLink = id => `${getDashboardUrl()}${id}`;

export const DashboardLink = ({ project }) => (
  <Link
    href={getDashboardLink(project.externalId)}
    underline="none"
    target="_blank"
    rel="noopener noreferrer"
  >
    {UI.DASHBOARD}&nbsp;
    <OpenInNewIcon fontSize="small" />
  </Link>
);

DashboardLink.propTypes = {
  project: PropTypes.shape({
    externalId: PropTypes.number,
  }).isRequired,
};

export const KickstarterLink = ({ project }) => (
  <Link
    href={project.url}
    underline="none"
    target="_blank"
    rel="noopener noreferrer"
  >
    {UI.KICKSTARTER}&nbsp;
    <OpenInNewIcon fontSize="small" />
  </Link>
);

KickstarterLink.propTypes = {
  project: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

import delay from '@redux-saga/delay-p';
import { call, put, race, take } from 'redux-saga/effects';
import { fetchProjectJobs } from '../actions';
import {
  FETCH_PROJECT_JOBS_FAILED,
  FETCH_PROJECT_JOBS_SUCCESS,
  STOP_JOBS_REFRESH,
} from '../constants';

const POLLING_TIME = 6000;

export function* handleJobsPolling({ payload }) {
  while (true) {
    yield put(fetchProjectJobs(payload.id));
    yield race([
      take(FETCH_PROJECT_JOBS_SUCCESS),
      take(FETCH_PROJECT_JOBS_FAILED),
    ]);

    yield delay(POLLING_TIME);
  }
}

export default function* handleJobsRefresh(action) {
  yield race([call(handleJobsPolling, action), take(STOP_JOBS_REFRESH)]);
}

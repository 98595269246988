import {
  ADD_COMPLETION_BUFFER,
  GET_BUFFER_COMPLETIONS_SUCCESS,
  REMOVE_COMPLETION_BUFFER,
} from '../constants';

export const initialState = {
  data: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BUFFER_COMPLETIONS_SUCCESS: {
      const { project, data } = payload;

      return {
        ...state,
        data: { ...state.data, [project]: data },
      };
    }

    case ADD_COMPLETION_BUFFER: {
      const { projectId, data } = payload;

      const newData = [...state.data[projectId], data];

      return { ...state, data: { ...state.data, [projectId]: newData } };
    }

    case REMOVE_COMPLETION_BUFFER: {
      const { projectId } = payload;

      const [, ...newData] = state.data[projectId];

      return { ...state, data: { ...state.data, [projectId]: newData } };
    }

    default:
      return state;
  }
};

export const getCompletions = state => state.completions;
export const getCompletionsData = state => getCompletions(state).data;
export const getBufferCompletion = state => project =>
  getCompletionsData(state)[project];

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  fetchSessionsSuccess,
  fetchSessionsFailed,
  loadSession,
} from '../actions';

export default function* handleFetchSessions({ payload }) {
  try {
    const data = yield call(backendAPI.sessions, {
      email: payload.email,
      'order[createdAt]': 'desc',
      itemsPerPage: 1,
    });
    yield put(fetchSessionsSuccess(data));

    const [session] = data;

    if (session) {
      const newState = JSON.parse(session.data);
      yield put(loadSession({ sessionId: session.uuid, state: newState }));
    }
  } catch (e) {
    yield put(fetchSessionsFailed(e));
  }
}

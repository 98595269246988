import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
  },
});

export default function CatCard() {
  const classes = useStyles();

  return (
    <div
      style={{
        padding: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card className={classes.root}>
        <CardMedia
          component="img"
          alt="Daily random cat"
          image="https://cataas.com/cat"
          title="Daily random cat"
        />
      </Card>
    </div>
  );
}

import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  getProjectFacebookAdsAccountsSuccess,
  getProjectFacebookAdsAccountsFailed,
} from '../actions';

export default function* handleFetchFacebookAdsAccounts({ payload }) {
  try {
    const id = payload.id || payload.projectId;

    const data = yield call(projectsAPI.projectFacebookAdsAccounts, id);

    yield put(getProjectFacebookAdsAccountsSuccess(id, data));
  } catch (e) {
    yield put(getProjectFacebookAdsAccountsFailed(e));
  }
}

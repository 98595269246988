import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const LoginButton = ({ className }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant="text"
      className={className}
      onClick={() => loginWithRedirect()}
    >
      Log In
    </Button>
  );
};

LoginButton.propTypes = {
  className: PropTypes.string,
};

export default LoginButton;

import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { importProjectSuccess, importProjectFailed } from '../actions';

export default function* handleProjectImport({ payload }) {
  try {
    const data = yield call(projectsAPI.importProject, payload.externalId);

    yield put(importProjectSuccess(data));
  } catch (e) {
    yield put(importProjectFailed(e));
  }
}

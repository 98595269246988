import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { dateFormat } from 'containers/Jobs/utils';

const DateDisplayer = ({ label, date, className }) =>
  date ? (
    <Typography className={className}>
      <b>{label}</b> &nbsp; {dateFormat(date)}
    </Typography>
  ) : null;

DateDisplayer.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
};

export default DateDisplayer;

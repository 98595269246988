import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { deleteMediaSuccess, deleteMediaFailed } from '../actions';

export default function* handleDeleteMedia({ payload }) {
  try {
    const { assets, projectId } = payload;

    // eslint-disable-next-line no-restricted-syntax
    for (const asset of assets) {
      // /api/media_object/{id}
      const [, , , id] = asset.split('/');

      yield call(backendAPI.deleteMedia, id);
    }

    yield put(deleteMediaSuccess(assets, projectId));
  } catch (e) {
    yield put(deleteMediaFailed(e));
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  ListItemText,
  Popper,
  createFilterOptions,
} from '@mui/material';

const ContentFitPopper = props => (
  <Popper
    {...props}
    style={{
      width: 'fit-content',
    }}
    placement="bottom-start"
  />
);

const filterOptions = createFilterOptions({
  stringify: option => `${option.text}${option.description}`,
});

export const SearchableDropdown = ({
  id,
  label,
  className,
  options = [],
  onChange,
  value,
  disabled,
  required,
}) => (
  <Autocomplete
    filterOptions={filterOptions}
    PopperComponent={ContentFitPopper}
    openOnFocus
    selectOnFocus
    disableClearable
    disabled={disabled}
    id={id}
    value={value}
    onChange={onChange}
    className={className}
    options={options}
    getOptionLabel={option => option.text || ''}
    renderInput={params => (
      <TextField {...params} required={required} label={label} />
    )}
    renderOption={(props, option) => (
      <MenuItem {...props} key={option.value} value={option.value}>
        <ListItemText primary={option.text} secondary={option.description} />
      </MenuItem>
    )}
  />
);

SearchableDropdown.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, () => null]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

const Dropdown = ({
  id,
  name,
  label,
  onChange,
  options = [],
  value,
  className,
  disabled,
  fullWidth,
  ...props
}) => (
  <FormControl
    variant="outlined"
    className={className}
    disabled={disabled}
    fullWidth={fullWidth}
    {...props}
  >
    <InputLabel id={id}>{label}</InputLabel>
    <Select
      aria-labelledby={id}
      label={label}
      labelId={id}
      // native
      displayEmpty
      value={value}
      onChange={onChange}
      sx={{ height: '100%' }}
      name={name}
    >
      <MenuItem aria-label="None" value="" disabled />
      {options.map(({ value: val, text, description }) => (
        <MenuItem key={val} value={val}>
          <ListItemText primary={text} secondary={description} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

Dropdown.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
  ),
};

export default Dropdown;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const LogoutButton = props => {
  const { logout } = useAuth0();

  return (
    <span>
      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        {...props}
      >
        Log Out
      </Button>
    </span>
  );
};

export default LogoutButton;

import { jobsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { stopJobSuccess, stopJobFailed } from '../actions';

export default function* handleStopJob({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(jobsAPI.updateJob, id, 'stopped');
    yield put(stopJobSuccess(data));
  } catch (e) {
    yield put(stopJobFailed(e));
  }
}

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  grantCustomAudiences,
  startJobsRefresh,
  stopJobsRefresh,
  stopJob,
} from 'containers/App/actions';
import { getSelectedProject } from 'containers/App/projects/reducer';
import Card from '@mui/material/Card';
import { alpha } from '@mui/material/styles';
import {
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageviewIcon from '@mui/icons-material/Pageview';
import TimedProgress from 'components/TimedProgress';
import { ExpandButton } from 'components/Buttons';
import Dropdown from 'components/Dropdown';
import orderBy from 'lodash/orderBy';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ReplayIcon from '@mui/icons-material/Replay';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import DateDisplayer from 'components/DateDisplayer';
import {
  openImportDataModal,
  openOptimizationModal,
} from 'containers/Modals/actions';
import { getJobsData } from './reducer';
import JobStatus, { FINISHED, STOPPED } from './JobStatus';
import { countDown } from './utils';
import Legend from './Legend';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px',
    maxWidth: 'fit-content',
  },
  card: {
    width: 400,
  },
  timer: {
    marginLeft: 'auto',
  },
  dropdown: {
    width: 'auto',
    minWidth: 200,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    paddingRight: theme.spacing(1),
  },
  toolbar: {
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    paddingTop: '15px',
  },
  cardHeader: {
    paddingBottom: '0px',
  },
  displayer: {
    paddingLeft: '0px',
  },
  jobType: {
    paddingTop: '5px',
    fontSize: '14px',
  },
}));

const UI = {
  TYPE: 'Type:',
  ERROR: 'Error:',
  SORT: 'Sort by',
  UPDATED_AT: 'Updated at:',
  INSERTED_AT: 'Inserted at:',
  BLOCKED_TILL: 'Blocked until:',
};

const SORT_OPTIONS = [
  { value: 'updatedAt', text: 'Last update' },
  { value: 'status', text: 'Status' },
  { value: 'messageType', text: 'Type' },
];

const SortDropdown = ({ value, onChange, className }) => (
  <Dropdown
    className={className}
    id="sort-select"
    label={UI.SORT}
    options={SORT_OPTIONS}
    value={value}
    onChange={onChange}
  />
);

SortDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const OrderButton = ({ value, onClick }) => (
  <IconButton aria-label={value} onClick={onClick}>
    {value === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
  </IconButton>
);

OrderButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const canRetry = job => job.messageType === 'facebook.custom_audience.share';

const canOptimize = job => job.messageType === 'facebook.strategy.one.full';

const isStrategy = job => job.messageType.split('.').at(1) === 'strategy';

const Jobs = ({
  project,
  jobs,
  fetchJobs,
  onUnmount,
  relaunch,
  onStop,
  onView,
  onOptimize,
}) => {
  useEffect(() => {
    if (project) {
      fetchJobs(project.uuid);
    }

    return onUnmount;
  }, [project]);

  const [sort, setSort] = useState(SORT_OPTIONS[0].value);
  const [order, setOrder] = useState('desc');
  const [expanded, setExpanded] = useState({});

  const handleExpand = id => {
    setExpanded({ ...expanded, [id]: !expanded[id] });
  };

  const classes = useStyles();

  const sortedJobs = orderBy(jobs, sort, order);

  const handleRelaunch = useCallback(() => relaunch(project['@id']), [
    relaunch,
    project,
  ]);

  return (
    <div>
      <span className={classes.toolbar}>
        <SortDropdown
          value={sort}
          onChange={e => setSort(e.target.value)}
          className={classes.dropdown}
        />
        <OrderButton
          value={order}
          onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
        />
        {project && (
          <TimedProgress
            delay={600}
            getDisplayLabel={countDown(10)}
            className={classes.timer}
          />
        )}
      </span>
      <div className={classes.wrapper}>
        <Grid className={classes.root} container spacing={1}>
          {sortedJobs.map(job => (
            <Grid item key={job.uuid}>
              <Card data-testid="job-card" className={classes.card}>
                <CardHeader
                  avatar={<JobStatus status={job.status} />}
                  title={job.uuid}
                  className={classes.cardHeader}
                  subheader={job.messageType}
                />
                <CardContent className={classes.cardContent}>
                  <DateDisplayer
                    label={UI.UPDATED_AT}
                    date={job.updatedAt}
                    className={classes.displayer}
                  />
                  <DateDisplayer
                    label={UI.INSERTED_AT}
                    date={job.insertedAt}
                    className={classes.displayer}
                  />
                  <DateDisplayer
                    label={UI.BLOCKED_TILL}
                    date={job.blockedTill}
                    className={classes.displayer}
                  />
                </CardContent>
                <CardActions disableSpacing>
                  {isStrategy(job) && (
                    <Tooltip title="View data">
                      <IconButton
                        aria-label="view data"
                        onClick={() => onView(job.uuid)}
                      >
                        <PageviewIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {canRetry(job) && (
                    <Tooltip title="Relaunch">
                      <IconButton aria-label="replay" onClick={handleRelaunch}>
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {canOptimize(job) && (
                    <Tooltip title="Optimize">
                      <IconButton
                        aria-label="optimize"
                        onClick={() => onOptimize(job)}
                      >
                        <CampaignIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {![STOPPED, FINISHED].includes(job.status) && (
                    <Tooltip title="Stop">
                      <IconButton
                        aria-label="stop"
                        onClick={() => onStop(job.uuid)}
                      >
                        <StopCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {job.errorMessage && (
                    <ExpandButton
                      expand={expanded[job.uuid]}
                      onClick={() => handleExpand(job.uuid)}
                      aria-expanded={expanded[job.uuid]}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandButton>
                  )}
                </CardActions>
                <Collapse in={expanded[job.uuid]} timeout="auto">
                  <CardContent>
                    <Typography>{UI.ERROR}</Typography>
                    <Typography variant="body2">{job.errorMessage}</Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Legend />
      </div>
    </div>
  );
};

Jobs.propTypes = {
  project: PropTypes.object,
  jobs: PropTypes.array.isRequired,
  fetchJobs: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  relaunch: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onOptimize: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  project: getSelectedProject(state),
  jobs: getJobsData(state),
});

const mapDispatchToProps = {
  fetchJobs: startJobsRefresh,
  onUnmount: stopJobsRefresh,
  relaunch: grantCustomAudiences,
  onStop: stopJob,
  onView: openImportDataModal,
  onOptimize: openOptimizationModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Jobs);

import { createSelector } from 'reselect';

import {
  UPLOAD_MEDIA,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILED,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
  CALC_ASPECT_RATIO_SUCCESS,
} from 'containers/App/constants';
import { get, keyBy } from 'lodash';
import { isVideo } from 'utils/mime';
import {
  getSelectedProjectId,
  getSelectedThumbnailByAdSetAndVideo,
} from '../reducer';

export const initialState = {
  data: {},
  uploading: false,
  deleting: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CALC_ASPECT_RATIO_SUCCESS:
      return {
        ...state,
        data: { ...state.data, [payload.id]: payload.data },
      };

    case UPLOAD_MEDIA:
      return { ...state, uploading: true };

    case UPLOAD_MEDIA_SUCCESS: {
      const { id, data } = payload;

      const mediaData = get(state, ['data', id], []);

      return {
        ...state,
        uploading: false,
        data: {
          ...state.data,
          [id]: [...mediaData, ...data],
        },
      };
    }

    case UPLOAD_MEDIA_FAILED:
      return { ...state, uploading: false };

    case DELETE_MEDIA:
      return { ...state, deleting: true };

    case DELETE_MEDIA_SUCCESS: {
      const { data: deletedMedia, projectId } = payload;
      const mediaData = state.data[projectId];

      return {
        ...state,
        deleting: false,
        data: {
          ...state.data,
          [projectId]: mediaData.filter(
            item => !deletedMedia.includes(item['@id']),
          ),
        },
      };
    }

    case DELETE_MEDIA_FAILED:
      return { ...state, deleting: false };

    default:
      return state;
  }
};

export const getMedia = state => state.media;
export const getMediaData = state => getMedia(state).data;
export const isUploading = state => getMedia(state).uploading;
export const isDeleting = state => getMedia(state).deleting;

export const getMediaOptions = createSelector(
  getMediaData,
  getSelectedProjectId,
  (data, projectId) =>
    data[projectId]
      ? data[projectId].map(media => ({
          value: media['@id'],
          url: media.url,
          name: media.originalName,
          mimeType: media.mimeType,
          aspectRatio: media.aspectRatio,
        }))
      : [],
);

export const getImageOptions = createSelector(
  getMediaOptions,
  data => data.filter(item => item.mimeType.startsWith('image')),
);

export const getVideoOptions = createSelector(
  getMediaOptions,
  data => data.filter(item => isVideo(item.mimeType)),
);

export const getMediaKeyById = createSelector(
  getMediaData,
  getSelectedProjectId,
  (data, id) => keyBy(data[id], '@id'),
);

export const getMediaOptionsWithThumbnails = createSelector(
  getMediaOptions,
  getMediaKeyById,
  getSelectedThumbnailByAdSetAndVideo,
  (assets, mediaMap, selector) => adSetIndex =>
    assets.map(asset => {
      const thumbnailImage = mediaMap[selector(adSetIndex, asset.value)];

      return {
        ...asset,
        thumbnail: thumbnailImage ? thumbnailImage.url : undefined,
      };
    }),
);

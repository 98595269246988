import { backendAPI } from 'api';
import { all, call, put, select } from 'redux-saga/effects';
import {
  getBufferCompletionsSuccess,
  getBufferCompletionsFailed,
} from '../actions';
import { getSelectedProjectId } from '../reducer';

const idWrap = id => `/api/projects/${id}`;

export default function* handleCompletionsBuffer() {
  try {
    const project = yield select(getSelectedProjectId);

    const buffer = yield all([
      call(backendAPI.completions, idWrap(project)),
      call(backendAPI.completions, idWrap(project)),
      call(backendAPI.completions, idWrap(project)),
    ]);

    yield put(getBufferCompletionsSuccess(project, buffer));
  } catch (e) {
    yield put(getBufferCompletionsFailed(e));
  }
}

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { deleteSessionsSuccess, deleteSessionsFailed } from '../actions';

export default function* handleSessionsDelete({ payload }) {
  try {
    const data = yield call(backendAPI.deleteSessions, payload.email);

    yield put(deleteSessionsSuccess(data));
  } catch (e) {
    yield put(deleteSessionsFailed(e));
  }
}

import {
  UPLOAD_MEDIA,
  UPLOAD_MEDIA_SUCCESS,
  UPLOAD_MEDIA_FAILED,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILED,
  FETCH_PROJECT_MEDIA,
  FETCH_PROJECT_MEDIA_SUCCESS,
  FETCH_PROJECT_MEDIA_FAILED,
  LAUNCH_ADS,
  LAUNCH_ADS_SUCCESS,
  LAUNCH_ADS_FAILED,
  PROJECT_SELECTED,
  GET_EXTERNAL_PROJECT,
  GET_EXTERNAL_PROJECT_SUCCESS,
  GET_EXTERNAL_PROJECT_FAILED,
  USER_DATA_UPDATED,
  CLEAR_PROJECT_DATA,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_FAILED,
  IMPORT_PROJECT,
  IMPORT_PROJECT_SUCCESS,
  IMPORT_PROJECT_FAILED,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILED,
  GRANT_PERMISSIONS,
  GRANT_PERMISSIONS_SUCCESS,
  GRANT_PERMISSIONS_FAILED,
  FETCH_CUSTOM_AUDIENCES,
  FETCH_CUSTOM_AUDIENCES_SUCCESS,
  FETCH_CUSTOM_AUDIENCES_FAILED,
  LOOKALIKE_DATA_UPDATED,
  GET_ACCOUNT_AUDIENCES,
  GET_ACCOUNT_AUDIENCES_SUCCESS,
  GET_ACCOUNT_AUDIENCES_FAILED,
  GET_ACCOUNT_MEDIA,
  GET_ACCOUNT_MEDIA_SUCCESS,
  GET_ACCOUNT_MEDIA_FAILED,
  AD_ACCOUNT_SELECTED,
  DAILY_BUDGET_UPDATED,
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_FAILED,
  FETCH_PROJECT_JOBS,
  FETCH_PROJECT_JOBS_SUCCESS,
  FETCH_PROJECT_JOBS_FAILED,
  START_JOBS_REFRESH,
  STOP_JOBS_REFRESH,
  UPLOAD_PROGRESS,
  APP_INIT,
  LOAD_IMAGE_FAILED,
  GRANT_CUSTOM_AUDIENCES,
  GRANT_CUSTOM_AUDIENCES_SUCCESS,
  GRANT_CUSTOM_AUDIENCES_FAILED,
  FETCH_FACEBOOK_PAGES,
  FETCH_FACEBOOK_PAGES_SUCCESS,
  FETCH_FACEBOOK_PAGES_FAILED,
  FORM_SELECTED,
  LAUNCH_GOOGLE_ADS_SUCCESS,
  LAUNCH_GOOGLE_ADS_FAILED,
  LAUNCH_GOOGLE_ADS,
  AUDIENCE_SIZE_UPDATED,
  DISPLAY_ARCHIVED_PROJECTS,
  GET_FACEBOOK_COMPLETIONS,
  GET_FACEBOOK_COMPLETIONS_SUCCESS,
  GET_FACEBOOK_COMPLETIONS_FAILED,
  FETCH_SESSIONS,
  FETCH_SESSIONS_SUCCESS,
  FETCH_SESSIONS_FAILED,
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILED,
  LOAD_SESSION,
  DELETE_SESSIONS,
  DELETE_SESSIONS_SUCCESS,
  DELETE_SESSIONS_FAILED,
  GET_PROJECT_FB_ADS_ACCOUNTS,
  GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS,
  GET_PROJECT_FB_ADS_ACCOUNTS_FAILED,
  GET_PROJECT_FB_PAGES,
  GET_PROJECT_FB_PAGES_SUCCESS,
  GET_PROJECT_FB_PAGES_FAILED,
  CALC_ASPECT_RATIO_SUCCESS,
  STOP_JOB,
  STOP_JOB_SUCCESS,
  STOP_JOB_FAILED,
  IMPORT_JOB_DATA,
  GET_PROJECT_STATUS,
  GET_PROJECT_STATUS_SUCCESS,
  GET_PROJECT_STATUS_FAILED,
  GET_BUFFER_COMPLETIONS,
  GET_BUFFER_COMPLETIONS_SUCCESS,
  GET_BUFFER_COMPLETIONS_FAILED,
  ADD_COMPLETION_BUFFER,
  REMOVE_COMPLETION_BUFFER,
  SET_PRESET,
  LAUNCH_BUDGET_OPT,
  LAUNCH_BUDGET_OPT_SUCCESS,
  LAUNCH_BUDGET_OPT_FAILED,
  ITERATION_UPDATED,
  CAMPAIGN_SETTINGS_UPDATED,
} from './constants';

export const INIT = {
  type: APP_INIT,
};

export const projectSelected = (projectId, externalId) => ({
  type: PROJECT_SELECTED,
  payload: { projectId, externalId },
});

export const formSelected = type => ({
  type: FORM_SELECTED,
  payload: { type },
});

export const userDataUpdated = data => ({
  type: USER_DATA_UPDATED,
  payload: { data },
});

export const clearProjectData = projectId => ({
  type: CLEAR_PROJECT_DATA,
  payload: { projectId },
});

export const setPreset = pages => ({
  type: SET_PRESET,
  payload: { pages },
});

export const uploadMedia = ({
  project,
  files,
  facebookAdsAccount,
  externalUrl,
}) => ({
  type: UPLOAD_MEDIA,
  payload: {
    project,
    files,
    facebookAdsAccount,
    externalUrl,
    message: 'Uploading assets...',
  },
});

export const uploadMediaSuccess = (data, id) => ({
  type: UPLOAD_MEDIA_SUCCESS,
  payload: {
    data,
    id,
    message: 'Assets uploaded successfully',
  },
});

export const uploadMediaFailed = error => ({
  type: UPLOAD_MEDIA_FAILED,
  payload: { error },
});

export const deleteMedia = (assets, projectId) => ({
  type: DELETE_MEDIA,
  payload: { assets, projectId },
});

export const deleteMediaSuccess = (data, projectId) => ({
  type: DELETE_MEDIA_SUCCESS,
  payload: {
    data,
    projectId,
    message: 'Assets deleted successfully',
  },
});

export const deleteMediaFailed = error => ({
  type: DELETE_MEDIA_FAILED,
  payload: { error },
});

export const uploadVideo = (file, facebookAdsAccount, thumbnail) => ({
  type: UPLOAD_VIDEO,
  payload: {
    file,
    facebookAdsAccount,
    thumbnail,
    message: 'Uploading Video...',
  },
});

export const uploadVideoSuccess = data => ({
  type: UPLOAD_VIDEO_SUCCESS,
  payload: { data, message: 'Video uploaded successfully' },
});

export const uploadVideoFailed = error => ({
  type: UPLOAD_VIDEO_FAILED,
  payload: { error },
});

export const fetchProjects = () => ({
  type: FETCH_PROJECTS,
});

export const fetchProjectsSuccess = data => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload: { data },
});

export const fetchProjectsFailed = error => ({
  type: FETCH_PROJECTS_FAILED,
  payload: { error },
});

export const fetchProjectMedia = projectId => ({
  type: FETCH_PROJECT_MEDIA,
  payload: { projectId },
});

export const fetchProjectMediaSuccess = (data, id) => ({
  type: FETCH_PROJECT_MEDIA_SUCCESS,
  payload: { data, id },
});

export const fetchProjectMediaFailed = error => ({
  type: FETCH_PROJECT_MEDIA_FAILED,
  payload: { error },
});

export const calcAspectRatioSuccess = (data, id) => ({
  type: CALC_ASPECT_RATIO_SUCCESS,
  payload: { data, id },
});

export const loadImageFailed = error => ({
  type: LOAD_IMAGE_FAILED,
  payload: { error },
});

export const launchAds = ({
  strategy,
  project,
  data,
  fbAdsAccountId,
  dailyBudget,
  audienceSize,
  audiences,
  campaignStatusActive,
  adSetBudget,
  schedule,
  mode,
  lookalikeCode,
  cboLookalikeCode,
  iteration,
}) => ({
  type: LAUNCH_ADS,
  payload: {
    strategy,
    project,
    data,
    fbAdsAccountId,
    dailyBudget,
    audienceSize,
    audiences,
    campaignStatusActive,
    adSetBudget,
    schedule,
    mode,
    lookalikeCode,
    cboLookalikeCode,
    iteration,
  },
});

export const launchAdsSuccess = data => ({
  type: LAUNCH_ADS_SUCCESS,
  payload: { data, message: 'Campaign created successfully' },
});

export const launchAdsFailed = error => ({
  type: LAUNCH_ADS_FAILED,
  payload: { error },
});

export const launchGoogleAds = data => ({
  type: LAUNCH_GOOGLE_ADS,
  payload: { ...data },
});

export const launchGoogleAdsSuccess = data => ({
  type: LAUNCH_GOOGLE_ADS_SUCCESS,
  payload: { data, message: 'Campaign launched successfully' },
});

export const launchGoogleAdsFailed = error => ({
  type: LAUNCH_GOOGLE_ADS_FAILED,
  payload: { error },
});

export const getExternalProject = externalId => ({
  type: GET_EXTERNAL_PROJECT,
  payload: { externalId },
});

export const getExternalProjectSuccess = data => ({
  type: GET_EXTERNAL_PROJECT_SUCCESS,
  payload: { data },
});

export const getExternalProjectFailed = error => ({
  type: GET_EXTERNAL_PROJECT_FAILED,
  payload: { error },
});

export const importProject = externalId => ({
  type: IMPORT_PROJECT,
  payload: { externalId, message: 'Importing project...' },
});

export const importProjectSuccess = data => ({
  type: IMPORT_PROJECT_SUCCESS,
  payload: { data, message: 'Project imported successfully' },
});

export const importProjectFailed = error => ({
  type: IMPORT_PROJECT_FAILED,
  payload: { error },
});

export const updateProject = id => ({
  type: UPDATE_PROJECT,
  payload: { id },
});

export const updateProjectSuccess = data => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: { data, message: 'Project updated successfully' },
});

export const updateProjectFailed = error => ({
  type: UPDATE_PROJECT_FAILED,
  payload: { error },
});

export const grantPermissions = facebookAdsAccount => ({
  type: GRANT_PERMISSIONS,
  payload: { facebookAdsAccount },
});

export const grantPermissionsSuccess = data => ({
  type: GRANT_PERMISSIONS_SUCCESS,
  payload: { data, message: 'Job launched...' },
});

export const grantPermissionsFailed = error => ({
  type: GRANT_PERMISSIONS_FAILED,
  payload: { error },
});

export const grantCustomAudiences = id => ({
  type: GRANT_CUSTOM_AUDIENCES,
  payload: { id, message: 'Job launched...' },
});

export const grantCustomAudiencesSuccess = data => ({
  type: GRANT_CUSTOM_AUDIENCES_SUCCESS,
  payload: { data },
});

export const grantCustomAudiencesFailed = error => ({
  type: GRANT_CUSTOM_AUDIENCES_FAILED,
  payload: { error },
});

export const displayArchivedProjects = display => ({
  type: DISPLAY_ARCHIVED_PROJECTS,
  payload: { display },
});

export const fetchCustomAudiences = () => ({
  type: FETCH_CUSTOM_AUDIENCES,
});

export const fetchCustomAudiencesSuccess = data => ({
  type: FETCH_CUSTOM_AUDIENCES_SUCCESS,
  payload: { data },
});

export const fetchCustomAudiencesFailed = error => ({
  type: FETCH_CUSTOM_AUDIENCES_FAILED,
  payload: { error },
});

export const lookalikeDataUpdated = (key, value) => ({
  type: LOOKALIKE_DATA_UPDATED,
  payload: { key, value },
});

export const dailyBudgetUpdated = data => ({
  type: DAILY_BUDGET_UPDATED,
  payload: { data },
});

export const iterationUpdated = data => ({
  type: ITERATION_UPDATED,
  payload: { data },
});

export const campaignSettingsUpdated = data => ({
  type: CAMPAIGN_SETTINGS_UPDATED,
  payload: { data },
});

export const audienceSizeUpdated = data => ({
  type: AUDIENCE_SIZE_UPDATED,
  payload: { data },
});

export const adAccountSelected = id => ({
  type: AD_ACCOUNT_SELECTED,
  payload: { id },
});

export const getAccountAudiences = id => ({
  type: GET_ACCOUNT_AUDIENCES,
  payload: { id },
});

export const getAccountAudiencesSuccess = data => ({
  type: GET_ACCOUNT_AUDIENCES_SUCCESS,
  payload: { data },
});

export const getAccountAudiencesFailed = error => ({
  type: GET_ACCOUNT_AUDIENCES_FAILED,
  payload: { error },
});

export const getAccountMedia = id => ({
  type: GET_ACCOUNT_MEDIA,
  payload: { id },
});

export const getAccountMediaSuccess = (data, id) => ({
  type: GET_ACCOUNT_MEDIA_SUCCESS,
  payload: { data, facebookAdsAccountsId: id },
});

export const getAccountMediaFailed = error => ({
  type: GET_ACCOUNT_MEDIA_FAILED,
  payload: { error },
});

export const fetchProjectJobs = id => ({
  type: FETCH_PROJECT_JOBS,
  payload: { id },
});

export const fetchProjectJobsSuccess = data => ({
  type: FETCH_PROJECT_JOBS_SUCCESS,
  payload: { data },
});

export const fetchProjectJobsFailed = error => ({
  type: FETCH_PROJECT_JOBS_FAILED,
  payload: { error },
});

export const getProjectFacebookAdsAccounts = id => ({
  type: GET_PROJECT_FB_ADS_ACCOUNTS,
  payload: { id },
});

export const getProjectFacebookAdsAccountsSuccess = (id, data) => ({
  type: GET_PROJECT_FB_ADS_ACCOUNTS_SUCCESS,
  payload: { id, data },
});

export const getProjectFacebookAdsAccountsFailed = error => ({
  type: GET_PROJECT_FB_ADS_ACCOUNTS_FAILED,
  payload: { error },
});

export const getProjectFacebookPages = id => ({
  type: GET_PROJECT_FB_PAGES,
  payload: { id },
});

export const getProjectFacebookPagesSuccess = (id, data) => ({
  type: GET_PROJECT_FB_PAGES_SUCCESS,
  payload: { id, data },
});

export const getProjectFacebookPagesFailed = error => ({
  type: GET_PROJECT_FB_PAGES_FAILED,
  payload: { error },
});

export const fetchFacebookPages = () => ({
  type: FETCH_FACEBOOK_PAGES,
});

export const fetchFacebookPagesSuccess = data => ({
  type: FETCH_FACEBOOK_PAGES_SUCCESS,
  payload: { data },
});

export const fetchFacebookPagesFailed = error => ({
  type: FETCH_FACEBOOK_PAGES_FAILED,
  payload: { error },
});

export const startJobsRefresh = id => ({
  type: START_JOBS_REFRESH,
  payload: { id },
});

export const stopJobsRefresh = id => ({
  type: STOP_JOBS_REFRESH,
  payload: { id },
});

export const stopJob = id => ({
  type: STOP_JOB,
  payload: { id },
});

export const stopJobSuccess = data => ({
  type: STOP_JOB_SUCCESS,
  payload: { data, message: 'Job stopped successfully' },
});

export const stopJobFailed = error => ({
  type: STOP_JOB_FAILED,
  payload: { error },
});

export const uploadProgress = (name, progress) => ({
  type: UPLOAD_PROGRESS,
  payload: { name, progress },
});

export const getFacebookCompletions = (project, index) => ({
  type: GET_FACEBOOK_COMPLETIONS,
  payload: { project, index },
});

export const getFacebookCompletionsSuccess = ({ data, project, index }) => ({
  type: GET_FACEBOOK_COMPLETIONS_SUCCESS,
  payload: { data, project, index },
});

export const getFacebookCompletionsFailed = (error, index) => ({
  type: GET_FACEBOOK_COMPLETIONS_FAILED,
  payload: { error, index },
});

export const getBufferCompletions = project => ({
  type: GET_BUFFER_COMPLETIONS,
  payload: { project },
});

export const getBufferCompletionsSuccess = (project, data) => ({
  type: GET_BUFFER_COMPLETIONS_SUCCESS,
  payload: { project, data },
});

export const getBufferCompletionsFailed = error => ({
  type: GET_BUFFER_COMPLETIONS_FAILED,
  payload: { error },
});

export const addCompletionBuffer = (projectId, data) => ({
  type: ADD_COMPLETION_BUFFER,
  payload: { projectId, data },
});

export const removeCompletionBuffer = projectId => ({
  type: REMOVE_COMPLETION_BUFFER,
  payload: { projectId },
});

export const fetchSessions = ({ email }) => ({
  type: FETCH_SESSIONS,
  payload: { email },
});

export const fetchSessionsSuccess = data => ({
  type: FETCH_SESSIONS_SUCCESS,
  payload: { data },
});

export const fetchSessionsFailed = error => ({
  type: FETCH_SESSIONS_FAILED,
  payload: { error },
});

export const createSession = ({ email } = {}) => ({
  type: CREATE_SESSION,
  payload: { email },
});

export const createSessionSuccess = ({ data, hash }) => ({
  type: CREATE_SESSION_SUCCESS,
  payload: { data, hash },
});

export const createSessionFailed = error => ({
  type: CREATE_SESSION_FAILED,
  payload: { error },
});

export const loadSession = ({ sessionId, state }) => ({
  type: LOAD_SESSION,
  payload: { state, sessionId },
});

export const deleteSessions = ({ email }) => ({
  type: DELETE_SESSIONS,
  payload: { email },
});

export const deleteSessionsSuccess = data => ({
  type: DELETE_SESSIONS_SUCCESS,
  payload: { data },
});

export const deleteSessionsFailed = error => ({
  type: DELETE_SESSIONS_FAILED,
  payload: { error },
});

export const importJobData = (data, project, history) => ({
  type: IMPORT_JOB_DATA,
  payload: { data, project, history },
});

export const getProjectStatus = (project, type) => ({
  type: GET_PROJECT_STATUS,
  payload: { project, type },
});

export const getProjectStatusSuccess = (project, data) => ({
  type: GET_PROJECT_STATUS_SUCCESS,
  payload: { project, data },
});

export const getProjectStatusFailed = error => ({
  type: GET_PROJECT_STATUS_FAILED,
  payload: { error },
});

export const launchBudgetOptimization = ({
  project,
  job,
  cboLookalikeCode,
  facebookAdsAccount,
}) => ({
  type: LAUNCH_BUDGET_OPT,
  payload: {
    project,
    job,
    facebookAdsAccount,
    cboLookalikeCode,
  },
});

export const launchBudgetOptimizationSuccess = data => ({
  type: LAUNCH_BUDGET_OPT_SUCCESS,
  payload: { data, message: 'Budget optimization launched successfully' },
});

export const launchBudgetOptimizationFailed = error => ({
  type: LAUNCH_BUDGET_OPT_FAILED,
  payload: { error },
});

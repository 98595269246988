import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';

const ANCHOR = {
  vertical: 'bottom',
  horizontal: 'center',
};

const TRANSFORM = {
  vertical: 'top',
  horizontal: 'right',
};

const MenuPopover = ({ children, className }) => {
  const menuElement = useRef();
  const [open, setOpen] = useState(false);

  const popoverId = open ? 'menu-button' : undefined;

  return (
    <>
      <IconButton
        ref={menuElement}
        aria-describedby={popoverId}
        onClick={() => setOpen(true)}
        aria-label="nav-menu"
      >
        <MenuIcon />
      </IconButton>
      <Popover
        id={popoverId}
        anchorEl={menuElement && menuElement.current}
        anchorOrigin={ANCHOR}
        transformOrigin={TRANSFORM}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={className}>{children}</div>
      </Popover>
    </>
  );
};

MenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default MenuPopover;

// Modal types
export const NONE = 'NONE';
export const THUMBNAIL_MODAL = 'THUMBNAIL_MODAL';
export const IMPORT_DATA_MODAL = 'IMPORT_DATA_MODAL';
export const OPTIMIZATION_MODAL = 'OPTIMIZATION_MODAL';
export const MODAL_TYPES = [
  NONE,
  THUMBNAIL_MODAL,
  IMPORT_DATA_MODAL,
  OPTIMIZATION_MODAL,
];

// actions
export const DISMISS_MODAL = 'DISMISS_MODAL';

export const OPEN_THUMBNAIL_MODAL = 'OPEN_THUMBNAIL_MODAL';
export const THUMBNAIL_UPDATED = 'THUMBNAIL_UPDATED';

export const OPEN_IMPORT_DATA_MODAL = 'OPEN_IMPORT_DATA_MODAL';
export const OPEN_OPTIMIZATION_MODAL = 'OPEN_OPTIMIZATION_MODAL';

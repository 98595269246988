import {
  FETCH_PROJECT_JOBS_SUCCESS,
  STOP_JOB_SUCCESS,
} from 'containers/App/constants';
import { createSelector } from 'reselect';
import { replaceAt } from 'utils/replaceAt';

export const initialState = {
  loading: false,
  data: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROJECT_JOBS_SUCCESS:
      return { ...state, data: payload.data.map(({ message, ...job }) => job) };

    case STOP_JOB_SUCCESS: {
      const updatedData = replaceAt(
        state.data,
        state.data.findIndex(item => item.uuid === payload.data.uuid),
        payload.data,
      );

      return {
        ...state,
        data: updatedData,
      };
    }

    default:
      return state;
  }
};

export const getJobs = state => state.jobs;
export const getJobsData = state => getJobs(state).data;
export const getJob = createSelector(
  getJobsData,
  jobs => id => jobs.find(job => job.uuid === id),
);

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { uploadVideoSuccess, uploadVideoFailed } from '../actions';

export default function* handleUploadVideo({ payload }) {
  try {
    const { file, facebookAdsAccount, thumbnail } = payload;

    const form = new FormData();

    form.append('file', file);
    form.append('facebookAdsAccount', facebookAdsAccount);
    form.append('thumbnail', thumbnail);

    const data = yield call(backendAPI.upload, form);

    yield put(uploadVideoSuccess(data));
  } catch (e) {
    yield put(uploadVideoFailed(e));
  }
}

import React from 'react';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CancelIcon from '@mui/icons-material/Cancel';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Tooltip } from '@mui/material';

export const WAITING = 'waiting';
export const RUNNING = 'running';
export const PAUSED = 'paused';
export const STOPPED = 'stopped';
export const FINISHED = 'finished';
export const SCHEDULED = 'scheduled';

export const DESCRIPTIONS = {
  [WAITING]: 'Job waiting to start',
  [RUNNING]: 'Job currently running',
  [PAUSED]: 'Job waiting for API limit to cool down',
  [FINISHED]: 'Job finished successfully',
  [STOPPED]: 'Job failed to finish',
  [SCHEDULED]: 'Job scheduled',
};

export const ALL = [WAITING, RUNNING, PAUSED, FINISHED, STOPPED, SCHEDULED];

const STATUS = {
  [WAITING]: (
    <Tooltip title={WAITING}>
      <HourglassEmptyIcon />
    </Tooltip>
  ),
  [RUNNING]: (
    <Tooltip title={RUNNING}>
      <DirectionsRunIcon />
    </Tooltip>
  ),
  [PAUSED]: (
    <Tooltip title={PAUSED}>
      <PauseCircleOutlineIcon />
    </Tooltip>
  ),
  [STOPPED]: (
    <Tooltip title={STOPPED}>
      <CancelIcon />
    </Tooltip>
  ),
  [FINISHED]: (
    <Tooltip title={FINISHED}>
      <CheckCircleOutlineIcon />
    </Tooltip>
  ),
  [SCHEDULED]: (
    <Tooltip title={SCHEDULED}>
      <ScheduleIcon />
    </Tooltip>
  ),
};

export default ({ status }) => STATUS[status];

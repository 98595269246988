import { googleAPI } from 'api';
import {
  STRATEGY_BRANDED_SEARCH,
  STRATEGY_PERFORMANCE,
  STRATEGY_SEARCH,
  GOOGLE_FIELDS,
} from 'containers/AdsPilot/constants';
import { call, put } from 'redux-saga/effects';
import { launchGoogleAdsSuccess, launchGoogleAdsFailed } from '../actions';

const mediaWrap = (id, extra) => ({ mediaObject: id, ...extra });

export default function* handleLaunch({ payload }) {
  const API_BY_STRATEGY = {
    [STRATEGY_SEARCH]: googleAPI.launchStrategyOne,
    [STRATEGY_BRANDED_SEARCH]: googleAPI.launchStrategyTwo,
    [STRATEGY_PERFORMANCE]: googleAPI.launchStrategyFive,
  };

  try {
    const { strategy, ...userData } = payload;

    const mediaData = {};

    switch (strategy) {
      case STRATEGY_PERFORMANCE:
        GOOGLE_FIELDS.MEDIA.forEach(field => {
          const media = userData[field] || [];
          mediaData[field] = media.map(mediaWrap);
        });

        break;
      default:
    }

    const campaignData = { ...userData, ...mediaData };

    const data = yield call(API_BY_STRATEGY[strategy], campaignData);

    yield put(launchGoogleAdsSuccess(data));
  } catch (e) {
    yield put(launchGoogleAdsFailed(e));
  }
}

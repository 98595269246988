import { backendAPI } from 'api';
import {
  BIDDING_CONTROL,
  MEDIA,
  STRATEGY_ONE,
  STRATEGY_TWO,
  STRATEGY_THREE,
  FB_PAGE,
} from 'containers/AdsPilot/constants';
import { call, put, select } from 'redux-saga/effects';
import numeral from 'numeral';
import flow from 'lodash/flow';
import { launchAdsSuccess, launchAdsFailed } from '../actions';
import { getSelectedThumbnailByAdSetAndVideo } from '../reducer';

const idWrap = (id, extra) => ({ '@id': id, ...extra });

export default function* handleLaunchAds({ payload }) {
  const API_BY_STRATEGY = {
    [STRATEGY_ONE]: backendAPI.launchStrategyOne,
    [STRATEGY_TWO]: backendAPI.launchStrategyTwo,
    [STRATEGY_THREE]: backendAPI.launchStrategyThree,
  };

  try {
    const {
      strategy,
      project,
      data,
      fbAdsAccountId,
      dailyBudget,
      audiences,
      campaignStatusActive,
      audienceSize,
      adSetBudget,
      schedule,
      mode,
      lookalikeCode,
      cboLookalikeCode,
      iteration,
    } = payload;

    const getThumbnail = yield select(getSelectedThumbnailByAdSetAndVideo);

    let creatives;

    switch (strategy) {
      case STRATEGY_ONE:
        creatives = buildMedia(creativesTransformation(data), getThumbnail);
        break;
      case STRATEGY_TWO:
        creatives = buildMedia(
          joinAudiences(creativesTransformation(data), audiences),
          getThumbnail,
        );
        break;
      case STRATEGY_THREE:
        creatives = buildMedia(creativesTransformation(data), getThumbnail);
        break;
      default:
        throw new Error('Unknown strategy');
    }

    const adsData = {
      project: idWrap(project['@id']),
      facebookAdsAccount: idWrap(fbAdsAccountId),
      creatives,
      dailyBudget: numeral(dailyBudget).value(),
      campaignStatusActive,
      audienceSize: numeral(audienceSize).value(),
      adSetBudget,
      schedule,
      mode,
      lookalikeCode,
      cboLookalikeCode,
      iteration,
    };

    const response = yield call(API_BY_STRATEGY[strategy], adsData);

    yield put(launchAdsSuccess(response));
  } catch (e) {
    yield put(launchAdsFailed(e));
  }
}

const wrapFacebookPage = data =>
  data.map(item => ({
    ...item,
    [FB_PAGE]: item[FB_PAGE] ? idWrap(item[FB_PAGE]) : null,
  }));

const biddingControlToNumber = data =>
  data.map(item => ({
    ...item,
    [BIDDING_CONTROL]: numeral(item[BIDDING_CONTROL]).value(),
  }));

const creativesTransformation = flow([
  biddingControlToNumber,
  wrapFacebookPage,
]);

const joinAudiences = (creatives, audiences) => {
  let count = 0;

  return creatives.map(creative => {
    {
      const newCreative = {
        ...creative,
        audiences: [[idWrap(audiences[count])], [idWrap(audiences[count + 1])]],
      };

      count += 2;

      return newCreative;
    }
  });
};

const buildMedia = (creatives, getThumbnail) =>
  creatives.map((creative, index) => {
    const media = creative[MEDIA].map(mediaId => {
      const thumbnail = getThumbnail(index, mediaId);
      return thumbnail
        ? idWrap(mediaId, { thumbnail: idWrap(thumbnail) })
        : idWrap(mediaId);
    });

    return { ...creative, [MEDIA]: media };
  });

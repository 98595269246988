import {
  DISMISS_MODAL,
  OPEN_IMPORT_DATA_MODAL,
  OPEN_OPTIMIZATION_MODAL,
  OPEN_THUMBNAIL_MODAL,
  THUMBNAIL_UPDATED,
} from './constants';

export const dismissModal = () => ({
  type: DISMISS_MODAL,
});

export const openThumbnailModal = (video, adSetIndex) => ({
  type: OPEN_THUMBNAIL_MODAL,
  payload: { video, adSetIndex },
});

export const openImportDataModal = uuid => ({
  type: OPEN_IMPORT_DATA_MODAL,
  payload: { uuid },
});

export const thumbnailUpdated = (video, adSetIndex, thumbnail) => ({
  type: THUMBNAIL_UPDATED,
  payload: { video, adSetIndex, thumbnail },
});

export const openOptimizationModal = job => ({
  type: OPEN_OPTIMIZATION_MODAL,
  payload: { job },
});

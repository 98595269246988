import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProgressBar from 'components/Progress';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Slide from '@mui/material/Slide';
import { getLabel, getProgress, isInProgress } from './reducer';

const useStyles = makeStyles(() => ({
  alert: {
    width: '200px',
  },
}));

const POSITION = {
  vertical: 'bottom',
  horizontal: 'right',
};

export function ProgressAlerts({ open, label, progress }) {
  const classes = useStyles();

  return (
    <Snackbar anchorOrigin={POSITION} open={open} TransitionComponent={Slide}>
      <Alert severity="info">
        <AlertTitle>{label}</AlertTitle>
        <ProgressBar className={classes.alert} value={progress} />
      </Alert>
    </Snackbar>
  );
}

ProgressAlerts.propTypes = {
  open: PropTypes.bool,
  label: PropTypes.string,
  progress: PropTypes.number,
};

const mapStateToProps = state => ({
  label: getLabel(state),
  progress: getProgress(state),
  open: isInProgress(state),
});

export default connect(mapStateToProps)(ProgressAlerts);

import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { fetchProjectMediaSuccess, fetchProjectMediaFailed } from '../actions';

export default function* handleFetchProjectMedia({ payload }) {
  try {
    const data = yield call(projectsAPI.projectMedia, payload.projectId);

    yield put(fetchProjectMediaSuccess(data, payload.projectId));
  } catch (e) {
    yield put(fetchProjectMediaFailed(e));
  }
}

import { backendAPI } from 'api';
import { call, put, select } from 'redux-saga/effects';
import objectHash from 'object-hash';
import { createSessionSuccess, createSessionFailed } from '../actions';
import { getApp } from '../reducer';
import { getHash, getUserEmail } from '../sessions/reducer';

export default function* handleCreateSession({ payload }) {
  try {
    const email = yield select(getUserEmail);
    const lastHash = yield select(getHash);

    const sessionState = yield select(getApp);

    const hash = objectHash(sessionState);

    if (hash !== lastHash) {
      const data = yield call(
        backendAPI.createSession,
        payload.email || email,
        JSON.stringify({ app: sessionState }),
      );

      yield put(createSessionSuccess({ data, hash }));
    } else {
      yield put(createSessionSuccess({ hash }));
    }
  } catch (e) {
    yield put(createSessionFailed(e));
  }
}

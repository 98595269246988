import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function TimedProgress({
  delay = 1000,
  className,
  getDisplayLabel,
}) {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress(prevProgress =>
        prevProgress >= 100 ? 0 : prevProgress + 10,
      );
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  return (
    <CircularProgressWithLabel
      className={className}
      value={progress}
      getDisplayLabel={getDisplayLabel}
    />
  );
}

TimedProgress.propTypes = {
  className: PropTypes.string,
  delay: PropTypes.number,
  getDisplayLabel: PropTypes.func,
};

function CircularProgressWithLabel({ getDisplayLabel, ...props }) {
  return (
    <Box {...props} sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {getDisplayLabel
            ? getDisplayLabel(props.value)
            : `${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  getDisplayLabel: PropTypes.func,
};

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  launchBudgetOptimizationSuccess,
  launchBudgetOptimizationFailed,
} from '../actions';

export default function* handleOptimizationLaunch({ payload }) {
  try {
    const data = yield call(backendAPI.campaignOptimization, payload);

    yield put(launchBudgetOptimizationSuccess(data));
  } catch (e) {
    yield put(launchBudgetOptimizationFailed(e));
  }
}

import axios from 'axios';

export class HttpClient {
  constructor() {
    HttpClient.instance = axios.create({ baseURL: process.env.API_BASE_URL });

    HttpClient.instance.interceptors.request.use(
      async config => {
        const token = await this.getToken();

        return {
          ...config,
          headers: {
            ...config.headers,
            'x-backercamp-service': 'ads-pilot-fe',
            Authorization: `Bearer ${token}`,
          },
        };
      },
      error => Promise.reject(error),
    );

    return this;
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    return this.tokenGenerator();
  }

  getInstance() {
    return HttpClient.instance;
  }
}

export default new HttpClient();

import React from 'react';
import PropTypes from 'prop-types';

export const FlexWrapper = ({ children }) => (
  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
    {children.map((c, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={i} style={{ flex: 1, margin: 4, minWidth: '250px' }}>
        {c}
      </div>
    ))}
  </div>
);

FlexWrapper.propTypes = {
  children: PropTypes.array,
};

export default function* handleRouteChange({ payload }) {
  const { data, project, history } = payload;

  const [form] = data.messageType.split('.');

  const [start] = window.location.pathname.split('/');

  history.push({
    pathname: [start, form, project].join('/'),
  });
}

const NO_RESPONSE_MESSAGE = 'No response was received';
const DEFAULT_ERROR_MESSAGE = 'Something weird happened';

/* eslint-disable prefer-promise-reject-errors */
export const errorHandler = error => {
  const { response, request } = error;

  if (response) {
    return Promise.reject({
      ...response.data,
      message: response.data['hydra:title'],
      description: response.data['hydra:description'],
      status: response.status,
    });
  }
  if (request) {
    // The request was made but no response was received
    return Promise.reject({
      message: NO_RESPONSE_MESSAGE,
      status: request.status,
    });
  }

  // Something happened in setting up the request that triggered an Error
  return Promise.reject({
    message: DEFAULT_ERROR_MESSAGE,
  });
};
/* eslint-enable prefer-promise-reject-errors */

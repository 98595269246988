import { projectsAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { fetchProjectJobsSuccess, fetchProjectJobsFailed } from '../actions';

export default function* handleFetchProjectJobs({ payload }) {
  try {
    const data = yield call(projectsAPI.projectJobs, payload.id);

    yield put(fetchProjectJobsSuccess(data));
  } catch (e) {
    yield put(fetchProjectJobsFailed(e));
  }
}

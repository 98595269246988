import orderBy from 'lodash/orderBy';
import { createSelector } from 'reselect';
import { GET_ACCOUNT_AUDIENCES_SUCCESS } from '../constants';

export const initialState = {
  loading: false,
  data: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ACCOUNT_AUDIENCES_SUCCESS:
      return { ...state, data: payload.data };

    default:
      return state;
  }
};

export const getAudiences = state => state.audiences;
export const getAudiencesData = state => getAudiences(state).data;
export const getAudiencesDataOrderByName = createSelector(
  getAudiencesData,
  audiences =>
    orderBy(audiences, [audience => audience.name.toLowerCase()], 'asc'),
);

export const getAudiencesOptions = createSelector(
  getAudiencesDataOrderByName,
  data =>
    data.map(project => ({
      value: project['@id'],
      text: project.name,
    })),
);

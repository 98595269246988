const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const dateFormat = d => {
  const date = new Date(d);

  return new Intl.DateTimeFormat(navigator.language, options).format(date);
};

const round = num => (Math.round(num * 100) / 100).toFixed(2);

export const countDown = start => progress => {
  const tick = start / 10;

  const displayValue = start - tick * (progress / 10);

  return parseInt(round(displayValue), 10);
};

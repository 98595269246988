import { call, put, all } from 'redux-saga/effects';
import { calcAspRatio, isVideo } from 'utils/mime';
import { loadImage } from 'utils/loadImage';
import { calcAspectRatioSuccess, loadImageFailed } from '../actions';

export default function* handleAspectRatio({ payload }) {
  const { data, id } = payload;

  const media = data.map(function* calc(item) {
    try {
      if (!isVideo(item.mimeType)) {
        const { naturalHeight, naturalWidth } = yield call(loadImage, item.url);

        return {
          ...item,
          aspectRatio: calcAspRatio(naturalWidth, naturalHeight),
        };
      }
    } catch (error) {
      yield put(loadImageFailed(error));
    }
    return item;
  });

  yield put(calcAspectRatioSuccess(yield all(media), id));
}

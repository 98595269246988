import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import { grantPermissionsSuccess, grantPermissionsFailed } from '../actions';

export default function* handleGrantPermissions({ payload }) {
  try {
    const data = yield call(
      backendAPI.grantPermissions,
      payload.facebookAdsAccount,
    );

    yield put(grantPermissionsSuccess(data));
  } catch (e) {
    yield put(grantPermissionsFailed(e));
  }
}

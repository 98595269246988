import { errorHandler } from './utils';

const PATH = 'api/jobs';

export default client => {
  const updateJob = (id, status) =>
    client
      .patch(
        `${PATH}/${id}`,
        { status },
        { headers: { 'Content-Type': 'application/merge-patch+json' } },
      )
      .then(({ data }) => data)
      .catch(errorHandler);

  return {
    updateJob,
  };
};

import { projectsAPI } from 'api';
import { call, put, select } from 'redux-saga/effects';
import { fetchProjectsSuccess, fetchProjectsFailed } from '../actions';
import { getProjectsStatus } from '../projects/reducer';

const PAGE_SIZE = 50;

export default function* handleFetchProjects() {
  try {
    const status = yield select(getProjectsStatus);

    const data = yield call(projectsAPI.getAllProjects, {
      status,
      itemsPerPage: PAGE_SIZE,
    });

    yield put(fetchProjectsSuccess(data));
  } catch (e) {
    yield put(fetchProjectsFailed(e));
  }
}

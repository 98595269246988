import httpClient from 'common/http-client';
import backend from './backend';
import google from './google';
import projects from './projects';
import jobs from './jobs';

const client = httpClient.getInstance();

export const backendAPI = backend(client);
export const googleAPI = google(client);
export const projectsAPI = projects(client);
export const jobsAPI = jobs(client);

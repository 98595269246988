import {
  UPLOAD_VIDEO_SUCCESS,
  IMPORT_JOB_DATA,
  LAUNCH_BUDGET_OPT_SUCCESS,
} from 'containers/App/constants';
import {
  DISMISS_MODAL,
  NONE,
  THUMBNAIL_MODAL,
  OPEN_THUMBNAIL_MODAL,
  OPEN_IMPORT_DATA_MODAL,
  IMPORT_DATA_MODAL,
  OPEN_OPTIMIZATION_MODAL,
  OPTIMIZATION_MODAL,
} from './constants';

const initialState = {
  type: NONE,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_THUMBNAIL_MODAL:
      return { type: THUMBNAIL_MODAL, data: { ...payload } };

    case OPEN_IMPORT_DATA_MODAL:
      return { type: IMPORT_DATA_MODAL, data: { ...payload } };

    case OPEN_OPTIMIZATION_MODAL:
      return { type: OPTIMIZATION_MODAL, data: { ...payload } };

    case LAUNCH_BUDGET_OPT_SUCCESS:
    case IMPORT_JOB_DATA:
    case UPLOAD_VIDEO_SUCCESS:
    case DISMISS_MODAL:
      return { type: NONE };

    default:
      return state;
  }
};

export const getModal = state => state.modals;
export const getModalType = state => getModal(state).type;
export const getModalData = state => getModal(state).data;

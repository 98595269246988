import { errorHandler } from './utils';

const PATH = 'api/google';

export default client => {
  const launchStrategyOne = adsData =>
    client
      .post(`${PATH}/campaign/strategy/one`, adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const launchStrategyTwo = adsData =>
    client
      .post(`${PATH}/campaign/strategy/two`, adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const launchStrategyFive = adsData =>
    client
      .post(`${PATH}/campaign/strategy/five`, adsData)
      .then(({ data }) => data)
      .catch(errorHandler);

  const status = payload =>
    client
      .post(`${PATH}/campaign/strategies/status`, payload)
      .then(({ data }) => data)
      .catch(errorHandler);

  return {
    launchStrategyOne,
    launchStrategyTwo,
    launchStrategyFive,
    status,
  };
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CheckBox from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import UploadIcon from '@mui/icons-material/UploadFileOutlined';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Avatar } from '@mui/material';
import { isVideo } from 'utils/mime';

const IMG_HEIGHT = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    minWidth: '250px',
    border: '1px solid transparent',
  },
  dragover: {
    border: '1px dashed black',
    borderRadius: '3px',
    backgroundColor: theme.palette.action.hover,
  },
  imageList: {
    minWidth: '100%',
    width: '100%',
    height: 450,
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: 'translateZ(0)',
    margin: '0',
  },
  imageItem: {
    cursor: 'pointer',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  video: {
    height: IMG_HEIGHT,
    width: '100%',
    '&::-webkit-media-controls-panel': {
      position: 'relative',
      top: '-40px',
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 50%, rgba(0,0,0,0) 100%)',
    },
  },
  videoBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  uploadButton: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3FE075',
    cursor: 'pointer',
  },
}));

const FILE_SIZE_LIMIT = 5e6; // 5 MB

const filterFilesBySize = files => {
  const filteredFiles = [];
  const validFiles = [];

  const arr = Array.from(files); // convert FileList

  arr.forEach(file => {
    if (file.size > FILE_SIZE_LIMIT && !isVideo(file.type)) {
      filteredFiles.push(file);
    } else {
      validFiles.push(file);
    }
  });

  return [validFiles, filteredFiles];
};

export default function MediaSelector({
  id,
  value = [],
  items = [],
  onChange,
  maxSize = items.length,
  onFilesDrop,
  allowUpload,
  onEditClick,
}) {
  const [dragover, setDragover] = React.useState(false);

  const classes = useStyles();

  const isSelected = useCallback(item => value.includes(item), [value]);

  const handleClick = item => {
    let selected = [];

    if (isSelected(item.value)) {
      selected = value.filter(i => i !== item.value);
    } else {
      selected = [...value, item.value];
      if (value.length >= maxSize) {
        selected.shift();
      }
    }

    onChange({ target: { id, value: selected } });
  };

  const handleFilesDrop = useCallback(
    files => {
      const [validFiles] = filterFilesBySize(files);

      onFilesDrop(validFiles);
    },
    [onFilesDrop],
  );

  const handleDrop = ev => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer && ev.dataTransfer.files.length > 0) {
      handleFilesDrop(ev.dataTransfer.files);
    } else if (ev.target.files && ev.target.files.length > 0) {
      handleFilesDrop(ev.target.files);
    }

    setDragover(false);
  };

  const handleDragOver = ev => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setDragover(true);
  };

  const handleDragLeave = () => {
    setDragover(false);
  };

  return (
    <div
      data-testid="media-selector"
      className={`${classes.root} ${dragover ? classes.dragover : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <ImageList
        rowHeight={IMG_HEIGHT}
        gap={1}
        cols={3}
        className={classes.imageList}
      >
        {allowUpload && (
          <ImageListItem>
            <label htmlFor="upload-file" className={classes.uploadButton}>
              <input
                style={{ display: 'none' }}
                id="upload-file"
                name="upload-file"
                type="file"
                multiple
                onChange={handleDrop}
                aria-label="File Upload"
              />
              <UploadIcon sx={{ color: 'white' }} fontSize="large" />
            </label>
          </ImageListItem>
        )}
        {items.map(item => (
          <ImageListItem
            className={classes.imageItem}
            onClick={() => handleClick(item)}
            key={item.url}
          >
            {isVideo(item.mimeType) ? (
              <VideoItem item={item} classes={classes} onClick={onEditClick} />
            ) : (
              <img src={item.url} alt={item.value} />
            )}
            <ImageListItemBar
              title={item.name}
              subtitle={item.aspectRatio}
              position="top"
              actionIcon={
                <IconButton
                  aria-label={`check ${item.value}`}
                  onClick={() => handleClick(item)}
                  className={classes.icon}
                  size="large"
                >
                  {isSelected(item.value) ? <CheckIcon /> : <CheckBox />}
                </IconButton>
              }
              className={classes.titleBar}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}

const VideoItem = ({ item, onClick, classes }) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <video
      className={classes.video}
      // poster={item.thumbnail}
      src={item.url}
      controls
      width="100%"
      height="100%"
    >
      <source
        data-testid="media-selector-source"
        src={item.url}
        type={item.mimeType}
      />
    </video>
    {onClick && (
      <ImageListItemBar
        className={classes.videoBar}
        position="bottom"
        actionIcon={
          <IconButton
            aria-label={`edit ${item.value}`}
            onClick={e => {
              e.stopPropagation();
              onClick(item.value);
            }}
            className={classes.icon}
            size="large"
          >
            {item.thumbnail ? (
              <Avatar src={item.thumbnail} alt="video thumbnail" />
            ) : (
              <AddPhotoAlternateIcon />
            )}
          </IconButton>
        }
      />
    )}
  </>
);

VideoItem.propTypes = {
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

MediaSelector.propTypes = {
  id: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  onFilesDrop: PropTypes.func,
  allowUpload: PropTypes.bool,
  onEditClick: PropTypes.func,
};

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  getAccountAudiencesSuccess,
  getAccountAudiencesFailed,
} from '../actions';

export default function* handleGetAudiences({ payload }) {
  try {
    // /api/facebook_ads_accounts/{id}
    const [, , , uuid] = payload.id.split('/');

    const data = yield call(backendAPI.facebookAdsAccountAudiences, uuid);

    yield put(getAccountAudiencesSuccess(data));
  } catch (e) {
    yield put(getAccountAudiencesFailed(e));
  }
}

import { backendAPI } from 'api';
import { call, put } from 'redux-saga/effects';
import {
  fetchCustomAudiencesSuccess,
  fetchCustomAudiencesFailed,
} from '../actions';

export default function* handleFetchCustomAudiences() {
  try {
    const data = yield call(backendAPI.customAudiences);

    yield put(fetchCustomAudiencesSuccess(data));
  } catch (e) {
    yield put(fetchCustomAudiencesFailed(e));
  }
}

import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import JobStatus, { ALL, DESCRIPTIONS } from './JobStatus';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'sticky',
    margin: '8px',
    borderRadius: '4px',
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
  listItemText: {
    color: alpha(theme.palette.common.black, 0.6),
    marginLeft: '10px',
  },
}));

const Legend = () => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {ALL.map(status => (
        <ListItem key={status}>
          <JobStatus status={status} />
          <ListItemText className={classes.listItemText}>
            {DESCRIPTIONS[status]}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default Legend;

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RefreshIcon from '@mui/icons-material/Refresh';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import MuiLoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const STYLES = { height: '28px', width: '28px' };

export const AddButton = ({ onClick, disabled }) => (
  <IconButton
    aria-label="add"
    style={STYLES}
    onClick={onClick}
    disabled={disabled}
    size="large"
  >
    <AddIcon />
  </IconButton>
);

AddButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const DeleteButton = ({ onClick, disabled }) => (
  <IconButton
    aria-label="delete"
    style={STYLES}
    onClick={onClick}
    disabled={disabled}
    size="large"
  >
    <DeleteIcon />
  </IconButton>
);

DeleteButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const ImportButton = ({ onClick, disabled, loading }) => (
  <MuiLoadingButton
    disabled={disabled}
    onClick={onClick}
    endIcon={<CloudDownloadIcon />}
    loading={loading}
    loadingPosition="end"
    color="secondary"
    variant="contained"
  >
    Import
  </MuiLoadingButton>
);

ImportButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const RefreshButton = ({ onClick, disabled }) => (
  <IconButton
    aria-label="refresh"
    style={STYLES}
    onClick={onClick}
    disabled={disabled}
    size="large"
  >
    <RefreshIcon />
  </IconButton>
);

RefreshButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const ButtonWrapper = ({ className, onAdd, onDelete, disabled }) => (
  <div className={className}>
    <AddButton onClick={onAdd} disabled={disabled} />
    <DeleteButton onClick={onDelete} disabled={disabled} />
  </div>
);

ButtonWrapper.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export const LoadingButton = ({ loading, disabled, children, ...props }) => (
  <Button variant="contained" {...props} disabled={disabled || loading}>
    {loading ? <CircularProgress size={24} /> : children}
  </Button>
);

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export const SetAllButton = ({ onClick, disabled }) => (
  <Tooltip title="Set value to all creatives">
    <span>
      <IconButton
        aria-label="set-all"
        onClick={onClick}
        disabled={disabled}
        size="large"
      >
        <DoneAllIcon />
      </IconButton>
    </span>
  </Tooltip>
);

SetAllButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const ClearAllButton = ({ onClick, disabled, className, ...props }) => (
  <Tooltip title="Clear project data">
    <span className={className}>
      <Button
        variant="outlined"
        aria-label="clear-all"
        onClick={onClick}
        disabled={disabled}
        startIcon={<ClearAllIcon />}
        {...props}
      >
        Clear
      </Button>
    </span>
  </Tooltip>
);

ClearAllButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const PresetButton = ({ className, ...props }) => (
  <span className={className}>
    <Button
      variant="outlined"
      aria-label="preset"
      startIcon={<AddCircleIcon />}
      {...props}
    >
      Preset
    </Button>
  </span>
);

PresetButton.propTypes = {
  className: PropTypes.string,
};

export const ExpandButton = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const GoogleButton = ({ size, ...props }) => (
  <IconButton aria-label="google" {...props}>
    <GoogleIcon fontSize={size} />
  </IconButton>
);

GoogleButton.propTypes = {
  size: PropTypes.string,
};

export const FacebookButton = ({ size, ...props }) => (
  <IconButton aria-label="facebook" {...props}>
    <FacebookIcon fontSize={size} />
  </IconButton>
);

FacebookButton.propTypes = {
  size: PropTypes.string,
};

export const GenerateButton = ({ onClick, disabled }) => (
  <Tooltip title="Generate">
    <span>
      <IconButton
        aria-label="generate"
        onClick={onClick}
        disabled={disabled}
        size="large"
      >
        <SmartToyIcon />
      </IconButton>
    </span>
  </Tooltip>
);

GenerateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export const CopyToClipboardButton = ({ onClick, disabled }) => (
  <Tooltip title="Copy to clipboard">
    <span>
      <IconButton
        aria-label="copy to clipboard"
        onClick={onClick}
        disabled={disabled}
        size="large"
      >
        <ContentCopyIcon />
      </IconButton>
    </span>
  </Tooltip>
);

CopyToClipboardButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

import {
  PRIMARY,
  HEADLINE,
  DESCRIPTION,
  BIDDING_CONTROL,
  CTA,
  SHOP_NOW,
  LEARN_MORE,
} from 'containers/AdsPilot/constants';

export const PRESETS = {
  default: [
    {
      [PRIMARY]:
        "XXXXX is now live on Kickstarter, don't miss out! Bring your tabletop adventures to life with this YYYYYY - plus detailed 2D/3D printed maps and VTT integration.",
      [HEADLINE]: 'Now Live: XXXX Tabletop 3D Mapmaking Software',
      [DESCRIPTION]: 'Only on Kickstarter. Check it out now.',
      [CTA]: SHOP_NOW,
      [BIDDING_CONTROL]: '',
    },
    {
      [PRIMARY]:
        '🤔 "It bridges the gap between imagination and reality" XXXXX is the YYYYYY of your dreams. Choose your tiles, sketch out your land, and watch your worlds come to life.',
      [HEADLINE]: '00000% Funded: XXXXXX is The Ultimate YYYYYYY',
      [DESCRIPTION]: 'Check it out on Kickstarter.',
      [CTA]: LEARN_MORE,
      [BIDDING_CONTROL]: '',
    },
    {
      [PRIMARY]:
        "🚀🎲 Special Kickstarter price ends soon, don't miss out! Craft every detail of your fantasy world with XXXX - the ultimate YYYYY.",
      [HEADLINE]:
        '000000% Funded: XXXXXXX With Detailed 2D/3D Printed Maps and VTT Integration',
      [DESCRIPTION]:
        'Only on Kickstarter. Pre-order now and save for a limited time',
      [CTA]: SHOP_NOW,
      [BIDDING_CONTROL]: '',
    },
  ],
};
